import { Container, SxProps, Theme } from "@mui/material"
import { PropsWithChildren } from "react"

interface Props {
    sx?: SxProps<Theme>
}
export const Section = ({ sx = {}, children }: PropsWithChildren<Props>) => {
    const style = { ...styles.section, ...sx }
    return (
        <Container component="section" sx={style}>
            {children}
        </Container>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    section: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        py: { xs: 3, lg: 8 },
        my: 0,
    },
}
