import { Box, SxProps, Theme } from "@mui/material"
import Link from "next/link"
import { PropsWithChildren } from "react"

interface Props extends PropsWithChildren {
    href: string
    target?: string
    color?: string
    nofollow?: boolean
}

export const InlineLink = ({
    href,
    target = "_self",
    color = "text.primary",
    nofollow = false,
    children,
}: Props) => {
    return (
        <Link rel={nofollow ? "nofollow" : undefined} href={href} target={target}>
            <Box component="span" sx={{ ...style, color, textDecorationColor: color }}>
                {children}
            </Box>
        </Link>
    )
}

const style: SxProps<Theme> = {
    textDecoration: "underline",
    fontWeight: 500,
}
