import { Box, SxProps, Theme } from "@mui/material"

import { PropsWithChildren } from "react"

interface Props extends PropsWithChildren {
    sx?: SxProps<Theme>
}

export const Glow = ({ sx = {} }: Props) => {
    const styles = { ...style, ...sx }
    return <Box sx={styles}></Box>
}

const style: SxProps<Theme> = {
    position: "absolute",
    zIndex: -1,
    width: "100%",
    height: { xs: "200px", md: "300px" },
    opacity: 0.1,
    background: "radial-gradient(70.71% 70.71% at 50% 50%, #4E5CDA 0%, rgba(0, 0, 0, 0.00) 70%)",
}
