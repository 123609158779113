"use client"
import { getTheme } from "@/theme"
import { Box, SxProps, Theme, alpha } from "@mui/material"
import { PropsWithChildren, useEffect, useRef, useState } from "react"

export const Highlight = ({ children }: PropsWithChildren) => {
    const [inView, setInView] = useState(false)
    const textRef = useRef(null)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setInView(true)
                } else {
                    setInView(false)
                }
            },
            {
                root: null,
                rootMargin: "-100px",
                threshold: 1,
            }
        )

        if (textRef.current) {
            observer.observe(textRef.current)
        }

        return () => {
            if (textRef.current) {
                observer.unobserve(textRef.current)
            }
        }
    }, [])

    return (
        <Box component="span" sx={styles.text} ref={textRef}>
            {children}
            <Box
                component="div"
                sx={inView ? { ...styles.glow, ...{ width: "120%", left: 0 } } : styles.glow}
            ></Box>
            <Box
                component="div"
                sx={inView ? { ...styles.centerGlow, ...{ width: "30%" } } : styles.centerGlow}
            ></Box>
        </Box>
    )
}

const theme = getTheme()

const styles: Record<string, SxProps<Theme>> = {
    text: {
        whiteSpace: "nowrap",
        position: "relative",
        background:
            "radial-gradient(70.71% 70.71% at 50% 50%,  #FFF 0%, rgba(255, 255, 255, 0.5)  100%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        color: "transparent",
    },
    glow: {
        position: "absolute",
        bottom: "-5px",
        left: "50%",
        width: "0%",
        transform: "translateX(-10%)",
        height: "1.5px",
        background: `linear-gradient(90deg, transparent, ${theme.palette.primary.dark} 25%,  ${theme.palette.primary.dark} 75%,transparent)`,
        borderRadius: "4px",
        boxShadow: `0 0 8px  ${alpha(theme.palette.primary.main, 0.6)}`,
        transition: "width 0.8s ease, left 0.8s ease",
    },

    centerGlow: {
        position: "absolute",
        bottom: "-5px",
        left: "50%",
        width: "0%",
        height: "1.5px",
        background: `linear-gradient(90deg, transparent, ${theme.palette.secondary.main}  50%,transparent)`,
        boxShadow: `0 0 8px ${theme.palette.secondary.main}`,
        transform: "translateX(-50%)",
        borderRadius: "4px",
        zIndex: 10,
        transition: "width 0.8s ease, left 0.8s ease",
    },
}
