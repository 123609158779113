"use client"
import { Box, SxProps, Theme } from "@mui/material"
import { Section } from "../layout/Section"
import { Title } from "../typography/Title"
import { ROUTES } from "@/constants/routes"
import { ActionButton } from "../buttons/ActionButton"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import { Glow } from "../Glow"

export default function CTA() {
    return (
        <Section sx={{ position: "relative", background: "none" }}>
            <SlideUpAnimation>
                <Title
                    sx={{
                        pt: { xs: 0, md: 1 },
                        pb: 4,
                    }}
                    component="h2"
                >
                    Cut your learning curve in half
                </Title>
            </SlideUpAnimation>
            <SlideUpAnimation delay="0.2s">
                <Box sx={styles.content}>
                    <ActionButton href={ROUTES.SIGNUP} aria-label="Signup link button">
                        Get started for free
                    </ActionButton>
                    <Glow />
                </Box>
            </SlideUpAnimation>
        </Section>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    content: {
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
        py: 4,
    },
}
