"use client"
import {
    APP_STORE_URL,
    CHROME_EXTENSION_STORE_URL,
    FIREFOX_RECALL_EXTENSION_STORE_URL,
    PLAY_STORE_URL,
} from "@/constants/routes"
import { Box, Button, Container, SxProps, Theme, alpha } from "@mui/material"
import Image from "next/image"
import Card from "../surfaces/Card"
import { Title, TitleSecondary } from "../typography/Title"
import SlideUpAnimation from "../utility/SlideUpAnimation"
import { getTheme } from "@/theme"

export default function Platforms() {
    const theme = getTheme()

    return (
        <Container component="section" sx={styles.wrapper}>
            <SlideUpAnimation>
                <Card
                    sx={{ px: 4 }}
                    glow={{
                        position: "50% 50%",
                        color: alpha(theme.palette.primary.main, 0.15),
                    }}
                >
                    <Title sx={{ pt: { xs: 0, md: 1 }, pb: 4 }} component="h2">
                        Available on your favorite platforms
                    </Title>
                    <Box sx={styles.content}>
                        <Box>
                            <TitleSecondary>Browsers Extensions</TitleSecondary>
                            <Box sx={styles.buttonContainer}>
                                <Button
                                    sx={styles.button}
                                    href={CHROME_EXTENSION_STORE_URL}
                                    target="_blank"
                                    aria-label="Install Chrome link button"
                                    startIcon={
                                        <Image
                                            width={25}
                                            height={25}
                                            src="/svgs/chrome.svg"
                                            alt="Chrome icon"
                                        />
                                    }
                                >
                                    Chrome
                                </Button>
                                <Button
                                    sx={styles.button}
                                    href={FIREFOX_RECALL_EXTENSION_STORE_URL}
                                    target="_blank"
                                    aria-label="Install Firefox link button"
                                    startIcon={
                                        <Image
                                            width={25}
                                            height={25}
                                            src="/svgs/firefox.svg"
                                            alt="Firefox icon"
                                        />
                                    }
                                >
                                    Firefox
                                </Button>
                            </Box>
                        </Box>
                        <Box>
                            <TitleSecondary>
                                Mobile Apps{" "}
                                <Box
                                    component="span"
                                    sx={{ color: "text.secondary", fontSize: "12px" }}
                                >
                                    in βeta
                                </Box>
                            </TitleSecondary>
                            <Box sx={styles.buttonContainer}>
                                <Button
                                    sx={styles.button}
                                    href={APP_STORE_URL}
                                    target="_blank"
                                    aria-label="Install iPhone App link button"
                                    startIcon={
                                        <Image
                                            width={25}
                                            height={25}
                                            src="/svgs/apple.svg"
                                            alt="Apple icon"
                                        />
                                    }
                                >
                                    iPhone
                                </Button>
                                <Button
                                    sx={styles.button}
                                    href={PLAY_STORE_URL}
                                    target="_blank"
                                    aria-label="Install Android App link button"
                                    startIcon={
                                        <Image
                                            width={25}
                                            height={25}
                                            src="/svgs/android.svg"
                                            alt="Android icon"
                                        />
                                    }
                                >
                                    Android
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </SlideUpAnimation>
        </Container>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    wrapper: {
        my: { xs: 2, lg: 12 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        py: 1,
        p: 4,
    },
    content: {
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        gap: 6,
        width: "100%",
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: 2,
        mt: 2,
        mb: { xs: 1, md: 0 },
    },
    button: {
        color: "background.default",
        borderRadius: 2,
        gap: 1,
        width: 180,
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        ":hover": {
            backgroundColor: "rgba(255, 255, 255, 0.9)",
        },
    },
}
